<template>
  <el-row class="table">
    <span class="title">题库管理</span>
    <el-row class="row-bg">
      <el-input
        placeholder="输入搜索标题、ID关键字"
        v-model.trim="queryData.inputVal"
        class="tb-search-input"
        @keyup.enter.native="search"
      >
        <el-select v-model="queryData.select" slot="prepend" placeholder="请选择" style="width: 120px;">
          <el-option label="题库标题" value="1"></el-option>
          <el-option label="题库ID" value="2"></el-option>
        </el-select>
        <i slot="prefix" class="el-input__icon el-icon-search"></i>
      </el-input>
      <el-select v-model="queryData.seleState" placeholder="选择状态" class="tb-smselect">
        <el-option v-for="item in queState" :key="item.value" :label="item.label" :value="item.value"></el-option>
      </el-select>
      <el-cascader
        class="td-cascader"
        v-model="queryData.catalogId"
        placeholder="请选择类别"
        :options="cascaderList"
        :props="{ checkStrictly: true, value: 'id', label: 'title' }">
      </el-cascader>
      <el-button type="primary" size="medium" @click="search" class="tb-button">搜 索</el-button>
      <el-button type="primary" size="medium" @click="reset" class="tb-button">重 置</el-button>
      <el-button type="info" size="medium" @click="add" class="tb-button" v-has="'quesbank:ManageAdd'">新增题库</el-button>
    </el-row>

    <el-table
      :data="dataList"
      border
      fit
      highlight-current-row
      class="tb-list"
      @selection-change="handleSeleChange"
    >
      <el-table-column type="selection" width="55" align="center"></el-table-column>
      <el-table-column label="题库ID" width="100" align="center" prop="contest_id"></el-table-column>
      <el-table-column label="题库标题" align="left" prop="title"></el-table-column>
      <el-table-column label="任务名称" align="left">
        <template slot-scope="scope">
          <span v-if="Boolean(scope.row.relationName)">
            {{scope.row.relationName.split(':')[0]}}
          </span>
          <span v-else></span>
        </template>
      </el-table-column>
      <el-table-column label="课节名称" align="left">
        <template slot-scope="scope">
          <span v-if="Boolean(scope.row.relationName)">
            {{scope.row.relationName.split(':')[1]}}
          </span>
          <span v-else></span>
        </template>
      </el-table-column>
      <el-table-column label="课程名称" align="left">
        <template slot-scope="scope">
          <span v-if="Boolean(scope.row.relationName)">
            {{scope.row.relationName.split(':')[2]}}
          </span>
          <span v-else></span>
        </template>
      </el-table-column>
      <el-table-column label="类别名称" align="left">
        <template slot-scope="scope">
          {{scope.row.catalogName}}
        </template>
      </el-table-column>
      <el-table-column label="包含问题" width="130" align="center" prop="problemNum"></el-table-column>
      <el-table-column label="状态" width="130" align="center">
        <template slot-scope="scope">
          <span v-text="getState(scope.row.defunct)" :style="'color'+':'+getStateColor(scope.row.defunct)"></span>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center" width="250">
        <template slot-scope="scope">
          <el-button size="small" type="primary" plain @click="edit(scope.row)" v-has="'quesbank:ManageEdit'">编辑</el-button>
          <el-button
            size="small"
            v-show="scope.row.defunct == 'N'"
            type="danger"
            plain
            @click="enableQues(scope.row)"
            style="margin-left: 10px;"
            v-has="'quesbank:ManageStop'"
          >停用</el-button>
          <el-button
            size="small"
            v-show="scope.row.defunct == 'Y'"
            type="primary"
            plain
            @click="enableQues(scope.row)"
            style="margin-left: 10px;"
          >启用</el-button>
          <el-button size="small" type="primary" plain @click="deleteItem(scope.row)" v-has="'quesbank:ManageDel'">删除</el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-dropdown trigger="click" class="tb-dropdown">
      <el-button type="info" size="medium">
        更多操作
        <i class="el-icon-arrow-down el-icon--right"></i>
      </el-button>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item @click.native="enableAccount">
          <i class="el-icon-video-play"></i>批量启用
        </el-dropdown-item>
        <el-dropdown-item @click.native="disabledAccount">
          <i class="el-icon-video-pause"></i>批量停用
        </el-dropdown-item>
        <el-dropdown-item @click.native="deleteAccount">
          <i class="el-icon-delete"></i>批量删除
        </el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>

    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="pagination.currentPage"
      :page-sizes="pagination.pageSizes"
      :page-size="pagination.pageSize"
      :layout="pagination.layout"
      :total="pagination.total"
    ></el-pagination>

    <!-- add -->
    <add-topic
      :show="addTopicDialog"
      :editData="editData"
      @close="addTopicDialog=false,editData=null"
      @getQuesibraryList="getQuesibraryList"
    ></add-topic>
  </el-row>
</template>

<script>
import AddTopic from '@/components/research/quesbank-manage/addTopic'
import getCourseType from '@/mixins/getCourseType'
import { getQuesibraryList, enableQues } from '@/api/research/quesBank'

export default {
  components: {
    AddTopic
  },
  mixins: [getCourseType],
  data () {
    return {
      queState: [
        {
          label: '已启用',
          value: 'N'
        },
        {
          label: '已停用',
          value: 'Y'
        }
      ],
      dataList: [],
      pagination: {
        currentPage: 1,
        pageSize: 10,
        pageSizes: [10, 30, 50, 100],
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0
      },
      addTopicDialog: false,
      editData: null,
      multipleSelection: [],
      queryData: {
        inputVal: '',
        select: '1',
        seleState: ''
      }
    }
  },
  created () {
    this.getQuesibraryList()
  },
  methods: {
    // 获取题库列表
    async getQuesibraryList () {
      const res = await getQuesibraryList({
        pageNum: this.pagination.currentPage,
        pageSize: this.pagination.pageSize,
        needCount: true,
        title: this.queryData.select === '1' ? this.queryData.inputVal : '',
        contest_id: this.queryData.select === '2' ? this.queryData.inputVal : '',
        defunct: this.queryData.seleState,
        catalogId: !this.queryData.catalogId ? null : this.queryData.catalogId[this.queryData.catalogId.length - 1]
      })
      this.dataList = res.body.list
      this.pagination.total = res.body.total
    },

    // 重置
    reset () {
      this.queryData = {
        inputVal: '',
        select: '1',
        seleState: ''
      }
    },

    // table 选中项
    handleSeleChange (val) {
      this.multipleSelection = val
    },

    // 搜索
    search () {
      if (this.queryData.select === '2') {
        let reg = /^[0-9]*$/
        if (!reg.test(this.queryData.inputVal)) {
          window.$msg('请输入正确的id', 2)
          return false
        }
      }
      this.pagination.currentPage = 1
      this.getQuesibraryList()
    },

    // 新建题库
    add () {
      this.editData = null
      this.addTopicDialog = true
    },

    // 分页
    handleCurrentChange (val) {
      this.pagination.currentPage = val
      this.getQuesibraryList()
    },
    handleSizeChange (val) {
      this.pagination.pageSize = val
      this.getQuesibraryList()
    },

    // 编辑
    edit (row) {
      this.editData = row
      this.addTopicDialog = true
    },

    getState (state) {
      switch (state) {
        case 'Y':
          return '已停用'
        case 'N':
          return '已启用'
        default:
          return '未知状态'
      }
    },
    getStateColor (state) {
      switch (state) {
        case 'Y':
          return '#CE433F'
        case 'N':
          return '#4DA54D'
        default:
          return '#F5C319'
      }
    },

    // 删除
    deleteItem (row) {
      this.$confirm('是否删除当前题库', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(async () => {
          await enableQues([{
            contest_id: row.contest_id,
            is_delete: 1
          }])
          window.$msg('删除成功')
          this.getQuesibraryList()
        })
        .catch(() => {
          return false
        })
    },

    // 启/停 用问题
    async enableQues (row) {
      if (row.defunct === 'N') {
        await enableQues([{
          contest_id: row.contest_id,
          defunct: 'Y'
        }])
        window.$msg('停用成功')
        this.getQuesibraryList()
      } else {
        await enableQues([{
          contest_id: row.contest_id,
          defunct: 'N'
        }])
        window.$msg('启用成功')
        this.getQuesibraryList()
      }
    },

    // 批量启用
    async enableAccount () {
      if (this.multipleSelection.length !== 0) {
        let enableList = []
        this.multipleSelection.map((item, index) => {
          enableList.push({ contest_id: item.contest_id, defunct: 'N' })
        })
        await enableQues(enableList)
        window.$msg('启用成功')
        this.getQuesibraryList()
      } else {
        window.$msg('请先选择问题', 2)
      }
    },

    // 批量停用
    async disabledAccount () {
      if (this.multipleSelection.length !== 0) {
        let disabledList = []
        this.multipleSelection.map((item, index) => {
          disabledList.push({ contest_id: item.contest_id, defunct: 'Y' })
        })
        await enableQues(disabledList)
        window.$msg('停用成功')
        this.getQuesibraryList()
      } else {
        window.$msg('请先选择问题', 2)
      }
    },

    // 批量删除
    deleteAccount () {
      if (this.multipleSelection.length !== 0) {
        this.$confirm('是否删除当前问题', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
          .then(async () => {
            let delList = []
            this.multipleSelection.map((item, index) => {
              delList.push({ contest_id: item.contest_id, is_delete: 1 })
            })
            await enableQues(delList)
            window.$msg('删除成功')
            this.getQuesibraryList()
          })
          .catch(() => {
            return false
          })
      } else {
        window.$msg('请先选择问题', 2)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../style/table.scss";
</style>
